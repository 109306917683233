import { FREE_TRIAL_DURATION } from 'constants/constants';
import styled from 'styled-components';
import CameraSnapshot from 'assets/camera-snapshot-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { IsMobile } from 'hooks/isMobile';
import { Button } from '@mui/material';
import { Flex } from 'styles';
import FeatureList from 'views/WhySubscribe/FeatureList';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${IsMobile() ? '100%' : '50%'};
  padding: ${IsMobile() ? '0px' : '20px'};
  background-color: ${IsMobile() ? '#fff' : '#fcfcfc'};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const OfferContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d7eea1;
  margin: 24px 0;
  cursor: pointer;
`;

const ExclusiveOffer = styled.div`
  display: flex;
  padding: 5px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
`;

const UnlockTitie = styled.div`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.64px;
  margin: 24px 0 12px;
`;

const UnlockInfo = styled.div`
  color: #494141;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`;

interface IProps {
  isEligibleForTrial: boolean;
  deviceId: string;
}

export default function NoSubscription(props: IProps) {
  const navigate = useNavigate();
  const { isEligibleForTrial, deviceId } = props;
  // debugger;
  return (
    <Flex justify="center">
      <Card>
        <Image src={CameraSnapshot} borderRadius="15" />
        <UnlockTitie>
          Unlock premium features for a more powerful camera!
        </UnlockTitie>
        {isEligibleForTrial && (
          <>
            <UnlockInfo>
              Choose the plan that fits your need: You have a{' '}
              {FREE_TRIAL_DURATION} days free trial available
            </UnlockInfo>
            <OfferContainer>
              <ExclusiveOffer
                onClick={() =>
                  navigate('/buySubscription', {
                    state: {
                      isFreeTrial: isEligibleForTrial,
                      isUpgrade: false,
                      deviceId
                    }
                  })
                }
              >
                Exclusive Offer
              </ExclusiveOffer>
              <Title>Try {FREE_TRIAL_DURATION} days Free-Trial</Title>
            </OfferContainer>
          </>
        )}

        <Flex justify="space-between" mbottom="10" direction="column">
          <FeatureList />
        </Flex>

        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '100%'
          }}
          onClick={() =>
            navigate('/buySubscription', {
              state: {
                isFreeTrial: isEligibleForTrial,
                isUpgrade: false,
                deviceId
              }
            })
          }
        >
          {isEligibleForTrial ? 'Continue With Free-Trial' : 'Buy'}
        </Button>
      </Card>
    </Flex>
  );
}
